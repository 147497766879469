<template>
  <v-container fluid>
    <note-modal
      :open-modal="openModal"
      :note="selectedNote"
      @modalClosed="(openModal = false), (selectedNote = {})"
    />
    <note-update
      :note="selectedNoteForUpdate"
      :open-modal="openEditModal"
      @updateModalCollapse="onUpdateModalClosed"
    />
    <v-row>
      <div
        class="not-found"
        v-if="notes.length === 0"
      >
        No notes found.
      </div>
      <v-col
        v-for="note in notes"
        :key="note.id"
        :cols="true"
      >
        <each-note-card
          :note="note"
          :modal-opened="openModal"
          @onToggleNoteExpansion="
            (note) => ((selectedNote = note), (openModal = true))
          "
          @openEditModal="onOpenEditModal"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EachNoteCard from "../note/card/EachNoteCard.vue";
import NoteModal from "../note/NoteModal.vue";
import { mapGetters } from "vuex";
import NoteUpdate from "../note/NoteUpdate.vue";

export default {
  name: "LabelNoteList",
  components: { EachNoteCard, NoteModal, NoteUpdate },
  computed: {
    ...mapGetters(["labelWiseNotes"]),
  },
  data() {
    return {
      notes: [],
      selectedNote: {},
      selectedNoteForUpdate: {},
      openModal: false,
      openEditModal: false,
    };
  },
  mounted() {
    this.notes = this.labelWiseNotes[this.$route.params.labelId] || [];
    this.$route.meta.title =
      this.notes.length > 0
        ? `Notes of "${this.notes[0].label.name}" | AmarDiary`
        : "Label Notes | AmarDiary";
  },
  methods: {
    onOpenEditModal(note) {
      this.openEditModal = true;
      this.selectedNoteForUpdate = note;
    },
    onUpdateModalClosed() {
      this.notes = this.labelWiseNotes[this.$route.params.labelId] || [];
      this.openEditModal = false;
      this.selectedNoteForUpdate = {};
    },
  },
  watch: {
    "$route.params.labelId"(newValue) {
      this.notes = this.labelWiseNotes[newValue] || [];
    },
    'labelWiseNotes': {
      handler (newValue) {
        this.notes = this.labelWiseNotes[newValue] || []
        this.$route.meta.title = this.notes.length > 0 ? `Notes of "${this.notes[0].label.name}" | My Space` : 'Label Notes | My Space'
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.not-found {
  margin: 3rem auto;
}
</style>