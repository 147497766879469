<template>
  <div>
    <label-note-list />
  </div>
</template>

<script>
import LabelNoteList from '../../components/keep/label/LabelNoteList.vue'

export default {
  components: { LabelNoteList },
  name: "LabelNoteListView"
}
</script>

<style scoped>
div {
  margin-top: 14px;
}
</style>